import axios from '../utils/axios'
import { UploadCSV } from '../models/upload_csv'
import { PaymentUpload } from '../models/payment_upload'

export const uploadPayments = async (file: File): Promise<UploadCSV> => {
  const formData = new FormData()
  formData.append('csvUpload', file)
  formData.set('timezone', `${Intl.DateTimeFormat().resolvedOptions().timeZone}`)
  const config = {
    headers: {
      'content-type': 'multipart/form-data',
    },
  }
  const res: ApiResponse = await axios.post('csv_upload', formData, config)
  return res.data
}

export const getPaymentUploads = async (): Promise<PaymentUpload[]> => {
  const res: ApiResponse = await axios.get('payment_uploads')
  return res.data
}

export const delPaymentUpload = async (id: number): Promise<number> => {
  const res: ApiResponse = await axios.delete(`payment_uploads/${id}`)
  return res.data
}

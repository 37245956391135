import React, { ReactElement, useContext, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { makeStyles, Theme, CircularProgress, Typography, Button } from '@material-ui/core'
import clsx from 'clsx'
import { navigate } from 'gatsby'
import csvSampleImage from '../../public/assets/sample_csv.png'
import { uploadPayments } from '../../services/upload.service'
import { UploadCSV, ValidatedRecord } from '../../models/upload_csv'
import InvalidRecords from './invalid-records.component'
import { MessageContext } from '../../contexts/message.store'

const useStyles = makeStyles((theme: Theme) => ({
  dropzone: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(10),
    borderWidth: '2px',
    borderRadius: theme.spacing(1),
    borderColor: theme.palette.secondary.light,
    borderStyle: 'dashed',
    maxWidth: '1039px',
    color: theme.palette.secondary.light,
    outline: 'none',
    transition: 'border .24s ease-in-out, color .24s ease-in-out',
    '&:hover': {
      borderColor: theme.palette.secondary.dark,
      color: theme.palette.secondary.dark,
    },
    '&:focus': {
      borderColor: theme.palette.secondary.dark,
      color: theme.palette.secondary.dark,
    },
  },
  headerWrapper: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: theme.spacing(4),
  },
  historyButton: {
    marginLeft: theme.spacing(4),
  },
  csvSampleContainer: {
    marginTop: theme.spacing(4),
    maxWidth: '1039px',
  },
  progressInvisible: {
    visibility: 'hidden',
  },
  progressHolder: {
    margin: '43px 0',
    width: '100%',
    display: 'flex',
  },
  progress: {
    margin: '0 auto',
  },
}))

export function UploadsComponent(): ReactElement {
  const classes = useStyles()
  const [, messageDispatch] = useContext(MessageContext)
  const [isLoading, setLoading] = useState(false)
  const [invalidRecords, setInvalidRecords] = useState<ValidatedRecord[]>([])
  const { getRootProps, getInputProps } = useDropzone({
    onDropAccepted: submitPayments,
  })
  async function submitPayments(files): Promise<void> {
    try {
      setLoading(true)
      setInvalidRecords([])
      await uploadPayments(files[0])
      messageDispatch({ type: 'SUCCESS', message: 'Payments uploaded and sales rep\'s emailed successfully' })
    } catch (err) {
      let message = err.status
      if (err.status.message) {
        const { records } = err.status.data as UploadCSV
        const invalids = records.filter((validatedRecord) => validatedRecord.badFields.length > 0)
        setInvalidRecords(invalids)
        message = err.status.message
      }
      messageDispatch({ type: 'ERROR', message })
    }
    setLoading(false)
  }(this)

  function goToHistory(): void {
    navigate('/uploads/history')
  }
  return (
    <div>
      <div className={classes.headerWrapper}>
        <Typography variant="h5" component="h5">Upload Payments</Typography>
        <Button className={classes.historyButton} variant="outlined" color="primary" onClick={goToHistory}>Upload History</Button>
      </div>
      {!isLoading ? (
        <section className="container">
          <div {...getRootProps({ className: classes.dropzone })}>
            <input {...getInputProps()} />
            <p>Drag your files here or click to select files</p>
          </div>
        </section>
      ) : (
        <div className={classes.progressHolder}>
          <CircularProgress
            size={183.5}
            className={clsx(classes.progress, { [classes.progressInvisible]: !isLoading })}
          />
        </div>
      )}
      <section className={classes.csvSampleContainer}>
        <Typography component="h3">Please Follow the Format Below</Typography>
        <img src={csvSampleImage} alt="Example CSV" />
      </section>
      <InvalidRecords invalidRecords={invalidRecords} />
    </div>
  )
}

import React, { ReactElement } from 'react'

import { AppComponent } from '../components/app/app.component'
import HeadComponent from '../components/head.component'
import { UploadsComponent } from '../components/uploads/uploads.component'
import LoginGuardComponent from '../components/app/login.gaurd.component'

export default function Uploads(): ReactElement {
  return (
    <AppComponent>
      <LoginGuardComponent>
        <HeadComponent title="Upload payments from anywhere" />
        <UploadsComponent />
      </LoginGuardComponent>
    </AppComponent>
  )
}

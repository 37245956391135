import React, { ReactElement } from 'react'
import { Tooltip, TableCell, Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import { ValidatedRecord, BadField } from '../../models/upload_csv'

type InvalidCellProps = {
  record: ValidatedRecord
  fieldPosition: number
  text: string
}

const useStyles = makeStyles((theme: Theme) => ({
  componentContainer: {
    marginTop: theme.spacing(4),
  },
  table: {
    minWidth: 650,
  },
  cell: {
    minWidth: 150,
  },
  invalidCell: {
    color: theme.palette.error.main,
  },
  tooltipInvisible: {
    display: 'none',
  },
}))

export default function InvalidCellComponent({ record, fieldPosition, text }: InvalidCellProps): ReactElement {
  const classes = useStyles()
  const getValidationsForField = (badFields: BadField[], cellIndex: number): BadField[] => {
    return badFields.filter((badField) => cellIndex === badField.index)
  }
  const getInvalidMessageForField = (badFields: BadField[], cellIndex: number): string => {
    const [message] = getValidationsForField(badFields, cellIndex)
    return message?.errorMessage[0]
  }

  const isInvalidField = (badFields: BadField[], cellIndex: number): boolean => {
    return getValidationsForField(badFields, cellIndex).length > 0
  }
  const invalidMessage = getInvalidMessageForField(record.badFields, fieldPosition)
  const isFieldInvalid = isInvalidField(record.badFields, fieldPosition)
  const invalidText = (): string => {
    return text || 'Error'
  }
  return (
    <>
      {isFieldInvalid ? (
        <Tooltip title={invalidMessage} placement="top" arrow>
          <TableCell className={clsx(classes.cell, classes.invalidCell)} component="th" scope="row">
            {invalidText()}
          </TableCell>
        </Tooltip>
      ) : (
        <TableCell className={classes.cell} component="th" scope="row">
          {text}
        </TableCell>
      )}
    </>
  )
}

import React, { ReactElement } from 'react'
import { makeStyles, Typography } from '@material-ui/core'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import { ValidatedRecord } from '../../models/upload_csv'
import InvalidCell from './invalid-cell.component'

type InvalidRecordsProps = {
  invalidRecords: ValidatedRecord[]
}
const useStyles = makeStyles((theme) => ({
  componentContainer: {
    marginTop: theme.spacing(4),
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 650,
  },
  cell: {
    minWidth: 150,
  },
  invalidCell: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
  },
}))

export default function InvalidRecordsComponent({ invalidRecords }: InvalidRecordsProps): ReactElement {
  const classes = useStyles()

  return (
    <>
      { invalidRecords.length > 0 ? (
        <section className={classes.componentContainer}>
          <Typography component="h3" className={classes.title}>Invalid Records. Hover highlighted cells for more information.</Typography>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Row</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>Rep Email</TableCell>
                  <TableCell>Rep Name</TableCell>
                  <TableCell>Line Item</TableCell>
                  <TableCell>Payment Type</TableCell>
                  <TableCell>Amount</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {invalidRecords.map((record) => (
                  <TableRow key={`${record.data.Row}`}>
                    <TableCell>{record.data.Row + 1}</TableCell>
                    <InvalidCell record={record} fieldPosition={0} text={record.data.Date} />
                    <InvalidCell record={record} fieldPosition={1} text={record.data.RepEmail} />
                    <InvalidCell record={record} fieldPosition={2} text={record.data.RepName} />
                    <InvalidCell record={record} fieldPosition={3} text={record.data.LineItem} />
                    <InvalidCell record={record} fieldPosition={4} text={record.data.PaymentType} />
                    <InvalidCell record={record} fieldPosition={5} text={record.data.Amount} />
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </section>
      ) : <></> }
    </>
  )
}
